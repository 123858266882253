<template>
	<div></div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const { router, route } = useRouter()
		const kakaoRedirectUrl = ref(`${window.location.origin}/auth/kakao-signin`)

		const kakaoSignin = async payload => {
			try {
				await AuthService.kakaoSignin(payload)
				await AuthService.init()
				const confirm = await successSwal('카카오톡으로 로그인 되었습니다')
				if (confirm.isConfirmed) {
					router.push({ name: 'mypage-dashboard' })
				}
			} catch (e) {
				const confirm = await warningSwal(
					e.response.status === 400
						? '카카오톡 계정과 연결된 계정이 존재하지 않습니다.<br/>로그인 후 카카오 계정을 연결해주세요.'
						: '카카오톡으로 로그인 하는데 문제가 발생되었습니다.',
				)
				if (confirm.isConfirmed) {
					router.push({ name: 'main' })
				}
			}
		}

		watch(
			() => route.value.query.code,
			currentValue => {
				if (currentValue) {
					kakaoSignin({
						code: currentValue,
						redirectUrl: kakaoRedirectUrl.value,
					})
				} else {
					router.go('mypage-dashboard')
				}
			},
			{
				immediate: true,
			},
		)

		return {}
	},
}
</script>
<style lang="scss" scoped></style>
